import { Footer } from 'flowbite-react';

export default function ShrtFooter() {
  return (
    <Footer container>
      <div className="w-full text-center">
        <Footer.Copyright href="#" by="Shortenify" year={2024} />
      </div>
    </Footer>
  );
}
