import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Route } from "./config/routes";
import { CustomFlowbiteTheme, Flowbite } from "flowbite-react";
import { Colors } from "./utils/ColorUtils";
import ShrtLanding from "./pages/landing-page/page";
import { IntlProvider } from "react-intl";
import { language, messages } from "./i18n/translate";
import ShrtPricing from "./pages/pricing/page";
import ShrtFeatures from "./pages/features/page";

const customTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary: Colors.INDIGO,
    },
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <ShrtLanding />,
      },
      {
        path: Route.Features,
        element: <ShrtFeatures />,
      },
      {
        path: Route.Home,
        element: <ShrtLanding />,
      },
      {
        path: Route.Pricing,
        element: <ShrtPricing />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <IntlProvider messages={messages} locale={language} defaultLocale="en">
      <Flowbite theme={{ theme: customTheme }}>
        <RouterProvider router={router} />
      </Flowbite>
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
