import { Button } from "flowbite-react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { HiOutlineExternalLink } from "react-icons/hi";
import { Route } from "../../config/routes";

const url = process.env.REACT_APP_APP_URL!;

export default function ShrtLanding() {
  return (
    <div className="text-center mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
      <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
        <FormattedMessage
          id="pages.landing.catch_phrase"
          defaultMessage="Short. Share. Track."
        />
      </h1>
      <p className="mt-6 text-lg leading-8 text-gray-600">
        <FormattedMessage
          id="pages.landing.description"
          defaultMessage="Shortenify is an API making it easy to generate short links."
        />
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <NavLink to={url}>
          <Button
            color="primary"
            className="text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FormattedMessage
              id="pages.landing.buttons.try"
              defaultMessage="Give it a try"
            />
            <HiOutlineExternalLink className="ml-1 h-5 w-5 shrink-0" />
          </Button>
        </NavLink>
        <NavLink
          to={Route.Features}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          <FormattedMessage
            id="pages.landing.buttons.more"
            defaultMessage="Learn more >"
          ></FormattedMessage>
        </NavLink>
      </div>
    </div>
  );
}
