import { flatten } from 'flat';
import translationsEn from './translations/translations.en.json';
import translationsFr from './translations/translations.fr.json';

const translations: Record<string, any> = {
  en: flatten(translationsEn),
  fr: flatten(translationsFr),
};

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
export const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  //   navigator.userLanguage ||
  'en';

// Split locales with a region code
export const shortLocale: string = language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, try locale without region code, fallback to 'en'
export const messages = translations[shortLocale] ?? translations[language] ?? translations.en;
