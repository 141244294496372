import { Button, Card, Tooltip } from 'flowbite-react';
import { ReactNode } from 'react';
import { HiCheck, HiCheckCircle, HiOutlineInformationCircle } from 'react-icons/hi';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Colors } from '../../utils/ColorUtils';

const strikeThroughStyle = 'line-through decoration-gray-500';

interface ShrtPricingPlanProps {
  apiKeysCount: number;
  currency?: string;
  graphiql?: boolean;
  links: number;
  name: string;
  passwordAuth?: boolean;
  planId: 'basic' | 'essential' | 'premium';
  pricing: number;
  subscriptions?: number;
}

interface ShrtPricingPlanItemsProps {
  label: ReactNode;
  missing?: boolean;
  tooltip?: ReactNode;
}

function ShrtPricingPlanItem({ label, missing, tooltip }: ShrtPricingPlanItemsProps) {
  return (
    <li className={`flex items-center space-x-3 ${missing ? strikeThroughStyle : ''}`}>
      <HiCheckCircle className={`h-5 w-5 shrink-0 ${missing ? Colors.GRAY : Colors.CYAN}`} />
      <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">{label}</span>
      {tooltip ? (
        <Tooltip content={tooltip}>
          <HiOutlineInformationCircle className={`h-5 w-5 shrink-0 ${missing ? Colors.GRAY : Colors.CYAN}`} />
        </Tooltip>
      ) : null}
    </li>
  );
}

export default function ShrtPricingPlan({
  apiKeysCount,
  currency = 'EUR',
  graphiql = true,
  links,
  name,
  passwordAuth,
  planId,
  pricing,
  subscriptions,
}: ShrtPricingPlanProps) {
  return (
    <Card className="max-w-sm">
      <h5 className="mb-4 text-xl text-center font-semibold text-gray-500 dark:text-gray-400">{name}</h5>
      <div className="flex items-baseline text-gray-900 dark:text-white">
        {/* <span className="text-3xl font-semibold">{currency}</span> */}
        <span className="text-5xl font-extrabold tracking-tight">
          <FormattedNumber
            value={pricing}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={currency}
          />
        </span>
        <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
          <FormattedMessage id="pages.pricing.labels.periodicity.monthly" defaultMessage="/month" />
        </span>
      </div>
      <ul className="my-7 space-y-5">
        <ShrtPricingPlanItem
          label={
            <FormattedMessage
              id="pages.pricing.labels.api_keys_count"
              defaultMessage="{api_keys_count, plural, one {<strong>#</strong> API key} other {<strong>#</strong> API keys}}"
              values={{
                api_keys_count: apiKeysCount,
                strong: (chunks) => <span className="text-gray-900 dark:text-white font-bold">{chunks}</span>,
              }}
            />
          }
          tooltip={
            <FormattedMessage
              id="pages.pricing.tooltips.api_keys_count"
              defaultMessage="{api_keys_count, plural, one {# API key} other {Up to # API keys}}"
              values={{ api_keys_count: apiKeysCount }}
            />
          }
        />
        <ShrtPricingPlanItem
          label={
            <FormattedMessage
              id="pages.pricing.labels.links"
              defaultMessage="<strong>{links}</strong> links/key per day"
              values={{
                links,
                strong: (chunks) => <span className="text-gray-900 dark:text-white font-bold">{chunks}</span>,
              }}
            />
          }
          tooltip={
            <FormattedMessage
              id="pages.pricing.tooltips.links"
              defaultMessage="You can create up to {links} links per API key per day"
              values={{
                links,
                strong: (chunks) => <span className="text-white font-bold">{chunks}</span>,
              }}
            />
          }
        />
        <ShrtPricingPlanItem
          label={
            <FormattedMessage
              id="pages.pricing.labels.subscriptions"
              defaultMessage="{subscriptions, plural, zero {No GrapQL subscription} other {Up to <strong>#</strong> GraphQL subscriptions}}"
              values={{
                subscriptions,
                strong: (chunks) => <span className="text-gray-900 dark:text-white font-bold">{chunks}</span>,
              }}
            />
          }
          tooltip={
            <FormattedMessage
              id="pages.pricing.tooltips.subscriptions"
              defaultMessage="{subscriptions, plural, =0 {You can not subscribe to the updates neither for your API key neither your links} other {You can have a maximum of <strong>#</strong> GraphQL subscriptions for getting live updates for some of your API keys and/or links}}"
              values={{
                subscriptions,
                strong: (chunks) => <span className="text-white font-bold">{chunks}</span>,
              }}
            />
          }
          missing={subscriptions === 0}
        />
        <ShrtPricingPlanItem
          label={<FormattedMessage id="pages.pricing.labels.graphiql" defaultMessage="Access to the GraphiQL page" />}
          tooltip={
            <FormattedMessage id="pages.pricing.tooltips.graphiql" defaultMessage="Dedicated GraphiQL page where you can go through the Shortenify API" />
          }
          missing={!graphiql}
        />
        <ShrtPricingPlanItem
          label={
            <FormattedMessage id="pages.pricing.labels.password" defaultMessage="Prevent your link to be opened without the credentials you have defined" />
          }
          tooltip={
            <FormattedMessage id="pages.pricing.tooltips.password" defaultMessage="Protect your link with Basic Authentication (username and password)" />
          }
          missing={!passwordAuth}
        />
      </ul>
      <NavLink to={`${process.env.REACT_APP_APP_URL}/billing?plan=${planId}`}>
        {/* <button
          type="button"
          className="inline-flex w-full justify-center rounded-lg bg-cyan-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-900"
        >
          Choose plan
        </button> */}
        <Button outline className="inline-flex w-full">
          <HiCheck className="h-5 w-5 shrink-0" />
          <FormattedMessage id="pages.pricing.buttons.choose_plan" defaultMessage="Choose plan" />
        </Button>
      </NavLink>
    </Card>
  );
}
