import ShrtPricingPlan from '../../components/common/PricingPlan';

export default function ShrtPricing() {
  return (
    <div className="flex justify-center">
      <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <ShrtPricingPlan
          key="basic"
          apiKeysCount={1}
          graphiql={false}
          links={10}
          name="Basic plan"
          passwordAuth={false}
          planId="basic"
          pricing={19.99}
          subscriptions={0}
        />
        <ShrtPricingPlan
          key="essential"
          apiKeysCount={2}
          links={25}
          name="Essential plan"
          passwordAuth={false}
          planId="essential"
          pricing={39.99}
          subscriptions={10}
        />
        <ShrtPricingPlan
          key="premium"
          apiKeysCount={5}
          links={50}
          name="Premium plan"
          passwordAuth={true}
          planId="premium"
          pricing={99.99}
          subscriptions={50}
        />
      </div>
    </div>
  );
}
