import { Button, Navbar } from 'flowbite-react';
import { HTMLAttributeAnchorTarget } from 'react';
import { HiOutlineLogin } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo_no_background.png';
import { Route } from '../config/routes';

const url = process.env.REACT_APP_APP_URL!;
const activeClass = 'bg-cyan-700 text-white dark:text-white md:bg-transparent md:text-cyan-700';
const defaultClass =
  'border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white';

function ShrtNavbarLink({ children, target, to }: { children: React.ReactNode; target?: HTMLAttributeAnchorTarget; to: string }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;
  return (
    <NavLink to={to} target={target} rel={rel} className="font-semibold text-sm">
      {({ isActive }) => <div className={isActive ? activeClass : defaultClass}>{children}</div>}
    </NavLink>
  );
}

export default function ShrtNavbar() {
  return (
    <Navbar fluid className="bg-transparent">
      <Navbar.Brand href={url}>
        <NavLink to={Route.Home} className="self-center whitespace-nowrap text-xl font-bold dark:text-white">
          <img src={logo} className="mr-3 h-6 sm:h-9" alt="Shortenify" />
        </NavLink>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <ShrtNavbarLink to={process.env.REACT_APP_APP_URL!}>
          <Button
            color="primary"
            className="text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <HiOutlineLogin className="mr-2 h-5 w-5" />
            <FormattedMessage id="navbar.menu.buttons.log_in" defaultMessage="Log in" />
          </Button>
        </ShrtNavbarLink>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <ShrtNavbarLink to={Route.Features}>
          <FormattedMessage id="navbar.menu.items.features" defaultMessage="Features" />
        </ShrtNavbarLink>
        <ShrtNavbarLink to={process.env.REACT_APP_API_DOC_URL!} target="_blank">
          <FormattedMessage id="navbar.menu.items.documentation" defaultMessage="Documentation" />
        </ShrtNavbarLink>
        <ShrtNavbarLink to={Route.Pricing}>
          <FormattedMessage id="navbar.menu.items.pricing" defaultMessage="Pricing" />
        </ShrtNavbarLink>
        <ShrtNavbarLink to={Route.About}>
          <FormattedMessage id="navbar.menu.items.about" defaultMessage="About" />
        </ShrtNavbarLink>
      </Navbar.Collapse>
    </Navbar>
  );
}
